<template>
  <div class="dateWrap">
    <div>{{ getDate[0] }}</div>
    <div>{{ getDate[1] }} {{ getWeek }}</div>
  </div>
</template>

<script>
import { WEEK_CONFIG } from "@/utils/time";
import moment from "moment";
export default {
  data() {
    return {
      momentDate: moment(),
    };
  },
  mounted() {
    setInterval(() => {
      this.momentDate = moment();
    }, 1000);
  },
  computed: {
    getWeek() {
      return WEEK_CONFIG[this.momentDate.get("day")];
    },
    getDate() {
      const year = this.momentDate.get("year");
      const month = this.momentDate.get("month") + 1;
      const day = this.momentDate.get("date");

      return [
        this.momentDate.format("HH:mm:ss"),
        `${year}年${month}月${day}日`,
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.dateWrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  & :first-child {
    margin-bottom: 6px;
  }
}
</style>
